import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Wizard from "./SettlementWizard";
import styled from "styled-components";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useAuth } from "../../../context/auth";
import { opscontroller } from "../../API/FP/Actions";
import Pagenotfound from "../../Common/pagenotfound";

const PtSt = ({
  partcicipantDetails,
  setShowParticipant,
  selectedRecordDetails,
}) => {
  const [selectedParticipantSettlement, setSelectedParticipantSettlement] =
    useState([]);

  const [mapDate, setMapData] = useState([partcicipantDetails]);
  const [showWiz, setshowWiz] = useState(false);
  console.log("hello srikar ", partcicipantDetails);
  return (
    <>
      {" "}
      {showWiz === false && (
        <>
          {" "}
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <h3>Participant Settlement List</h3>
          </div>
          <table
            style={{
              width: "10%",
              borderCollapse: "collapse",
            }}
          >
            <thead>
              <tr>
                <th style={styles.cell}>ID</th>
                <th style={styles.cell}>Participant Id</th>
                <th style={styles.cell}>Participant Name</th>
                <th style={styles.cell}>Gst Id</th>
                <th style={styles.cell}>Settlement Type</th>
                <th style={styles.cell}>Charge Type</th>
                <th style={styles.cell}>Settlement Amount</th>
                <th style={styles.cell}>Settlement Due Date</th>
                <th style={styles.cell}>Patron Of Payment</th>
                <th style={styles.cell}>Recipient Of Payment</th>
                <th style={styles.cell}>Participant Settlement Status</th>
                <th style={styles.cell}>Actions</th>
              </tr>
            </thead>
            <tbody>
              {partcicipantDetails?.map((row, index) => {
                const completedCount = partcicipantDetails
                  .slice(0, index)
                  .filter(
                    (item) => item.participantSettlementStatus === "PSCD"
                  ).length;

                const shouldTriggerOutput =
                  completedCount === 2 &&
                  row.participantSettlementStatus === "PSCR";

                return (
                  <tr key={index} style={{ textAlign: "center" }}>
                    <td style={styles.cell}>{row?.id}</td>
                    <td style={styles.cell}>{row?.participantId}</td>
                    <td style={styles.cell}>{row?.participantName}</td>
                    <td style={styles.cell}>{row?.gstId}</td>
                    <td style={styles.cell}>{row?.settlementType}</td>
                    <td style={styles.cell}>{row?.chargeType}</td>
                    <td style={styles.cell}>{row?.settlementAmount}</td>
                    <td style={styles.cell}>{row?.settlementDueDate}</td>
                    <td style={styles.cell}>{row?.patronOfPayment}</td>
                    <td style={styles.cell}>{row?.recipientOfPayment}</td>
                    <td style={styles.cell}>
                      {row?.participantSettlementStatus}
                    </td>
                    <td style={styles.cell}>
                      {row?.chargeType === "FP_TRANSACTION_CHARGES" ||
                      row?.chargeType === "CRE_TRANSACTION_FEES" ? (
                        <p>Not Applicable</p>
                      ) : (
                        <Button
                          style={{
                            borderRadius: "5px",
                            backgroundColor: "#4A3AFF",
                            color: "white",
                            border: "none",
                            cursor: "pointer",
                            height: "fit-content",
                            boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                            opacity:
                              row?.participantSettlementStatus !== "PSCR"
                                ? 0.5
                                : 1,
                          }}
                          onClick={() => {
                            // Trigger any further action here
                            setshowWiz(true);
                            setSelectedParticipantSettlement(row);
                          }}
                          disabled={row?.participantSettlementStatus !== "PSCR"}
                        >
                          {row?.participantSettlementStatus !== "PSCR"
                            ? "Completed"
                            : "Proceed"}
                        </Button>
                      )}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          <div>
            <button
              style={{
                margin: "10px",
                color: "black",
                height: "45px",
                width: "120px",
                backgroundColor: "white",
                border: " 1px #404040 solid",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "20px 36px",
                borderRadius: "2px",
                cursor: "pointer",
                alignSelf: "flexStart",
              }}
              onClick={() => setShowParticipant(false)}
            >
              Back
            </button>
          </div>
        </>
      )}
      {showWiz && (
        <Wizard
          selectedRecordDetails={selectedRecordDetails}
          selectedParticipantSettlement={selectedParticipantSettlement}
        />
      )}
    </>
  );
};

export default PtSt;

const styles = {
  cell: {
    border: "1px solid #ddd", // Border for table cells
    padding: "8px", // Padding for table cells
    textAlign: "center", // Center align text
  },
};
