import React, { useCallback, useEffect, useState } from "react";
import { styled } from "styled-components";
import { useLocation, useNavigate } from "react-router-dom";
import { callAPI, controllerAPI } from "../../API/FP/Actions";
import {
  contextObj,
  contextObjController,
  dateFormat,
  kycContextObjController,
  onAcceptedOfferContextObjController,
  presignedContextObjController,
  requestStates,
} from "../../Common/Constants";
import {
  Alert,
  Box,
  Checkbox,
  CircularProgress,
  Input,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { NumericFormat } from "react-number-format";
import { useAuth } from "../../../context/auth";
import { PlacedRequestInvoicesData } from "../../MockData/AnchorTraderMock";
import Modal from "../../Common/Modal";
import UpdatesIframe from "../../Common/UpdatesIframe";

function RequestOfferFullDetails({
  setShowFullOfferDetails,
  data,
  financeRequestData,
}) {
  const location = useLocation();
  const label = { inputProps: { "aria-label": "Checkbox demo" } };
  const requestData = location.state?.requestData || {};
  const token = location.state?.authToken;
  const [presignedURl, setPresignedURL] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [auth, setAuth] = useAuth();
  const [checkBoxValue, setCheckboxValue] = useState(false);
  const [acceptDeclaration, setAcceptDeclaration] = useState(
    data?.isLoanAgreementTOSSelected
  );
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [apiResultMessage, setApiResultMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("");
  const [modalOpen, setModalOpen] = useState(false);
  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 1,
  };

  const [anchorTraderID, setAnchorTraderID] = useState(
    auth?.userDetails?.personaIds["ANCHOR_TRADER-1"]
  );

  const today = new Date();
  const yyyy = today.getFullYear();
  let mm = today.getMonth() + 1; // Months start at 0!
  let dd = today.getDate();
  let hours = today.getHours();
  let minutes = today.getMinutes();
  let seconds = today.getSeconds();
  if (hours < 10) hours = "0" + hours;
  if (minutes < 10) minutes = "0" + minutes;
  if (seconds < 10) seconds = "0" + seconds;
  if (dd < 10) dd = "0" + dd;
  if (mm < 10) mm = "0" + mm;

  const formattedToday =
    yyyy +
    "-" +
    mm +
    "-" +
    dd +
    "T" +
    hours +
    ":" +
    minutes +
    ":" +
    seconds +
    "Z";
  console.log("from the expanded :", data);

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpenSnackbar(false);
  };
  const fetchToken = useCallback(async () => {
    setIsLoading(true);
    try {
      const constructObj1 = presignedContextObjController(
        authContextObj,
        anchorTraderID,
        "103"
      );
      const presignedURLResponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj1,
        "get-presigned-url"
      );

      if (presignedURLResponse.res.status === 200) {
        setPresignedURL(presignedURLResponse.res.data.data.presigned_url);
      }
    } catch (err) {
      console.log("Error", err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  const saveProfileDetails = async () => {
    console.log("the request offer ulid is :", data.reqOffUlidId);

    let formattedPlacedOffer = data;
    const acceptedObj = {
      reqOffUlidId: data.reqOffUlidId,
      value: data.value,
      reqAmount: data.reqAmount,
      marginPtg: data.marginPtg,
      marginValue: data.marginValue,
      amountAftMargin: data.amountAftMargin,
      interestPtg: data.interestPtg,
      term: data.term,
      interestValue: data.interestValue,
      netAmount: data.netAmount,
      status: "OFAD",
      offerDate: data.placedOfferDate,
      offerAcceptedDate: formattedToday,
      //financerequest: data?.financerequest,
      //financerequest: financeRequestData,
      anchortrader: financeRequestData?.anchortrader,
      financepartner: data?.financepartner,
      placedOffer: formattedPlacedOffer,
      placedOfferUlidId: data.placedOfferUlidId,
    };

    formattedPlacedOffer["status"] = "OFPD";
    formattedPlacedOffer["isLoanAgreementTOSSelected"] = acceptDeclaration;
    setIsLoading(true);
    try {
      const constructObj = onAcceptedOfferContextObjController(
        authContextObj,
        data.placedOfferUlidId,
        financeRequestData.financeRequestUlidId,
        {
          acceptedOffer: acceptedObj,
          placedOffer: formattedPlacedOffer,
          financeRequest: financeRequestData,
        }
      );
      const saveAcceptedOfferResponse = await controllerAPI(
        "post",
        auth?.token,
        constructObj,
        "on-accept-offer"
      );

      if (saveAcceptedOfferResponse.res.status === 200) {
        setOpenSnackbar(true);
        setApiResultMessage("Offer Accepted Successfully");
        setAlertSeverity("success");
        window.location.reload();
      }
    } catch (err) {
      console.log("Error", err);
      setOpenSnackbar(true);
      setApiResultMessage("Error Occured. Please try later");
      setAlertSeverity("error");
    } finally {
      setIsLoading(false);
    }
  };
  const CloseModal = () => {
    setModalOpen(false);
  };

  useEffect(() => {
    fetchToken();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    saveProfileDetails();
  };

  return (
    <>
      <div>
        {modalOpen ? (
          <Modal
            bgcolor={"#EEEEEE"}
            ordered={modalOpen}
            left={"3%"}
            modelClosed={CloseModal}
          >
            <UpdatesIframe
              closefunction={CloseModal}
              updateUrl={presignedURl}
              updateName={"updateName"}
              video={false}
            />
          </Modal>
        ) : null}
        {isLoading ? (
          <>
            <CircularProgress
              style={{
                position: "absolute",
                left: "50%",
                top: "50%",
                zIndex: "1100",
              }}
            />
          </>
        ) : (
          <form className="form" onSubmit={handleSubmit}>
            <RequestInformation className="body-text">
              <div className="main-content">
                <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
                  {" "}
                  Request details
                </h3>
                <div className="record-details">
                  <table style={{ width: "auto" }}>
                    <tbody>
                      <tr>
                        <th>Date of Request</th>
                        <td>{financeRequestData?.requestDate}</td>
                      </tr>
                      <tr>
                        <th>Total trade value (INR)</th>
                        <td>
                          <NumericFormat
                            displayType="text"
                            value={data.value}
                            thousandsGroupStyle="lakh"
                            thousandSeparator=","
                          />
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </RequestInformation>
            <div style={{ display: "flex" }}>
              <SideTables className="body-text" style={{ width: "50%" }}>
                <div className="main-content">
                  <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
                    {" "}
                    Disbursement details
                  </h3>
                  <div className="record-details">
                    <table style={{ width: "auto" }}>
                      <tbody>
                        <tr>
                          <th> Financing Margin(%)</th>
                          <td>{data?.marginPtg}</td>
                        </tr>
                        <tr>
                          <th>Margin value (INR)</th>
                          <td>{data?.marginValue}</td>
                        </tr>
                        <tr>
                          <th>Amount after margin (INR)</th>
                          <td>{data?.amountAftMargin}</td>
                        </tr>
                        <tr>
                          <th>Annualized Interest rate (%)</th>
                          <td>{data?.interestPtg}%</td>
                        </tr>
                        <tr>
                          <th>Interest value (INR)</th>
                          <td>{data?.interestValue}</td>
                        </tr>
                        <tr>
                          <th>Amount to be disbursed (INR)</th>
                          <td>{data?.disbursalAmount}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </SideTables>
              <SideTables className="body-text" style={{ width: "50%" }}>
                <div className="main-content">
                  <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
                    {" "}
                    Repayment Details
                  </h3>
                  <div className="record-details">
                    <table style={{ width: "auto" }}>
                      <tbody>
                        <tr>
                          <th>Repayment Tenure (Days)</th>
                          <td>{data?.term}</td>
                        </tr>
                        <tr>
                          <th>Repayment to ProFinTech (INR)</th>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={data?.value}
                              thousandsGroupStyle="lakh"
                              thousandSeparator=","
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Transaction charges (%)</th>
                          <td>{data?.atTransactionChargesInPercentage}%</td>
                        </tr>
                        <tr>
                          <th>Transaction charge amount (INR)</th>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={data?.atTransactionCharges}
                              thousandsGroupStyle="lakh"
                              thousandSeparator=","
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Repayment to finance partner (INR)</th>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={data?.fpRepaymentAmount}
                              thousandsGroupStyle="lakh"
                              thousandSeparator=","
                            />
                          </td>
                        </tr>
                        <tr>
                          <th>Balance payment to you (INR)</th>
                          <td>
                            <NumericFormat
                              displayType="text"
                              value={data?.atRepaymentAmount}
                              thousandsGroupStyle="lakh"
                              thousandSeparator=","
                            />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </SideTables>
            </div>
            <RequestInformation className="body-text">
              <div className="main-content">
                <h3 style={{ marginBottom: "5px", color: "#4A3AFF" }}>
                  {" "}
                  Summary
                </h3>
                <div className="record-details">
                  <table style={{ width: "auto" }}>
                    <tbody>
                      <tr>
                        <th>Trade Value (INR)</th>
                        <td>{data.value}</td>
                      </tr>
                      <tr>
                        <th>Interest charges</th>
                        <td>{data.interestValue}</td>
                      </tr>
                      <tr>
                        <th>Transaction charges</th>
                        <td>{data.atTransactionChargesInPercentage}%</td>
                      </tr>
                      <tr>
                        <th>Net amount received by you</th>
                        <td>{data.netAmountAfterCharges}</td>
                      </tr>
                      <tr>
                        <th>Effective Interest Rate</th>
                        <td>{data.effectiveInterestRate}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </RequestInformation>
            <h4 style={{ marginBottom: "0px", marginTop: "20px" }}>
              {" "}
              {data.status !== "OFPD" ? (
                <Checkbox {...label} checked={true} disabled={true} required />
              ) : (
                <Checkbox
                  {...label}
                  checked={acceptDeclaration}
                  onChange={() => setAcceptDeclaration(!acceptDeclaration)}
                  required
                />
              )}
              I have read and accepted the{" "}
              <span
                style={{ color: "#4A3AFF", cursor: "pointer" }}
                onClick={() => setModalOpen(true)}
              >
                Terms and Conditions
              </span>{" "}
              of Loan Agreement.
            </h4>

            <div style={{ marginTop: "15px", textAlign: "center" }}>
              {data.status !== "OFPD" ? (
                <>
                  {" "}
                  <Typography
                    variant="body1"
                    color="green"
                    sx={{
                      marginBottom: "10px",
                    }}
                  >
                    Offer Accepted!
                  </Typography>
                </>
              ) : (
                <></>
              )}

              <button
                style={{
                  borderRadius: "2px",
                  backgroundColor: "#FFF",
                  color: "#4A3AFF",
                  border: "1px solid rgba(0, 0, 128, 0.20)",
                  padding: "10px 20px",
                  cursor: "pointer",
                  borderRadius: "2px",
                  height: "fit-content",
                  boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                  //   opacity: checkBoxValue === true ? "1" : "0.5",
                }}
                onClick={() => setShowFullOfferDetails(false)}
              >
                Back
              </button>
              <button
                style={{
                  marginLeft: "10px",
                  borderRadius: "2px",
                  backgroundColor: "#4A3AFF",
                  color: "white",
                  border: "none",
                  padding: "10px 20px",
                  cursor: "pointer",
                  borderRadius: "2px",
                  height: "fit-content",
                  boxShadow: "0px 3px 5px 0px rgba(0, 0, 0, 0.10)",
                  opacity:
                    acceptDeclaration && data.status === "OFPD" ? "1" : "0.5",
                }}
                disabled={
                  data.status !== "OFPD" && acceptDeclaration ? true : false
                }
              >
                Accept Offer
              </button>
            </div>
          </form>
        )}

        <Snackbar
          open={openSnackbar}
          autoHideDuration={6000}
          onClose={handleClose}
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        >
          <Alert
            onClose={handleClose}
            severity={alertSeverity}
            sx={{ width: "100%" }}
          >
            {apiResultMessage}
          </Alert>
        </Snackbar>
      </div>
      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default RequestOfferFullDetails;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 45% !important;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 7px !important;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    width: 55%;
    // background: rgba(74, 58, 255, 0.1);
  }
  .main-content {
    margin-top: 30px;
  }
`;

const Dash = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 90% !important;
    border-collapse: collapse;
    // border-left: 1px solid rgba(0, 0, 128, 0.2);
    // border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    // box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 7px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    // width: 55%;
    background: rgba(74, 58, 255, 0.1);
  }
  .main-content {
    margin-top: 30px;
  }
`;

const SideTables = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 90% !important;
    border-collapse: collapse;
    border-left: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 2px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 7px !important;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    width: 55%;
    // background: rgba(74, 58, 255, 0.1);
  }
  .main-content {
    margin-top: 30px;
  }
`;

//hello !
