import { React, useState, useEffect } from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { useAuth } from "../../context/auth";
import newLogoExpand from "../../components/images/logoExpand.svg";
import ProFinTechlogo from "../../components/images/ProFinTech-logo.png";
import Footer from "../Common/Footer";
import { CheckBox } from "@mui/icons-material";
import ProFinTechLogoCompressed from "../images/ProFinTechLogoCompressed.png";
import Signup from "./Signup";
import QuoteImg from "../images/quote.png";
import SignupTextLogo from "../images/SignupLogo.png";
import CreateAccountLogo from "../images/CreateAccountLogo.png";
function Signin() {
  const [role, setRole] = useState("seller");
  const [auth, setAuth] = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [username, setUsername] = useState("");
  const [organizationID, setOrganizationID] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);
  const [rememberMe, setRememberMe] = useState(false);

  //routing
  const handleNavigate = (res) => {
    const roles = res?.data?.roles;
    setRole(res?.data?.defaultPersona);
    if (res?.data?.defaultPersona === "ANCHOR_TRADER") {
      navigate("/creditbazaar/anchortrader-profile");
    } else if (res?.data?.defaultPersona === "TRADE_PARTNER") {
      navigate("/creditbazaar/buyer-request-information");
    } else if (res?.data?.defaultPersona === "FINANCE_PARTNER") {
      navigate("/creditbazaar/offer-information");
    } else if (res?.data?.defaultPersona === "PFT") {
      navigate("/creditbazaar/AcceptedOffer");
    } else {
      navigate("/creditbazaar/offer-information");
    }
  };
  const PasswordChange = (e) => {
    let val = e.target.value;
    let updatedValue = val.replace(/\s+/g, "");
    setPassword(updatedValue);
    if (error) setError(null);
  };
  const orgChange = (e) => {
    let val = e.target.value;
    let updatedValue = val.replace(/\s+/g, "");
    setOrganizationID(updatedValue);
    if (error) setError(null);
  };
  const userNameChange = (e) => {
    let val = e.target.value;
    let updatedValue = val.replace(/\s+/g, "");
    setUsername(updatedValue);
    if (error) setError(null);
  };
  useEffect(() => {
    const rememberedUsername = localStorage.getItem("username");
    const rememberedorganizationID = localStorage.getItem("organizationID");
    const rememberMeChecked = localStorage.getItem("rememberMe") === "true";

    if (rememberedorganizationID && rememberedUsername && rememberMeChecked) {
      setUsername(rememberedUsername);
      setOrganizationID(rememberedorganizationID);
      setRememberMe(true);
    }
  }, []);

  ///login
  const handleSubmit = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    try {
      delete axios.defaults.headers.common["Authorization"];
      const res = await axios.post(
        `${process.env.REACT_APP_API}/api/tenant/signin`,
        {
          username,
          password,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "X-TenantID": `${organizationID}`,
          },
        }
      );

      setTimeout(() => {
        setIsLoading(false);
        // Perform the actual login action here
      }, 2000);
      // console.log(res.data);
      if (res.status === 200) {
        // alert(res.data.message);
        setAuth({
          ...auth,
          user: res ? res.data.username : localStorage.getItem("auth").user,
          userDisplayName: res && res.data.companyName,
          token: res
            ? res.data.accessToken
            : localStorage.getItem("auth").token,
          userDetails: res.data,
        });
        // console.log({ auth });
        localStorage.setItem("auth", JSON.stringify(res.data));
        if (rememberMe) {
          localStorage.setItem("username", username);
          localStorage.setItem("organizationID", organizationID);
          localStorage.setItem("rememberMe", "true");
        } else {
          localStorage.removeItem("username");
          localStorage.removeItem("organizationID");
          localStorage.removeItem("rememberMe");
        }

        handleNavigate(res);
      } else {
        setError("Invalid credentials. Please try again.");
        setIsLoading(false);
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        setError("Invalid credentials. Please try again.");
        setIsLoading(false);
      } else {
        /* setAuth({
          ...auth,
          user: res.data.username,
          token: res.data.accessToken,
        });*/
        setError("Something went wrong. Please try again later.");
        setIsLoading(false);
      }
    }
  };

  console.log({ auth });
  return (
    <>
      <div className="TotalWrap" style={{ display: "flex", height: "100vh" }}>
        <div
          className="section"
          style={{
            flex: 1,
            backgroundColor: "#297FFF",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <div className="textComponent">
            <img src={CreateAccountLogo} />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "50%",
          }}
        >
          <div className="Logo">
            <img src={ProFinTechLogoCompressed} />
          </div>

          <div
            className="section"
            style={{
              flex: 1,
              backgroundColor: "white",
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {/* Right section content */}
            <Sign1>
              {" "}
              <div
                className="Sign1"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                  }}
                ></div>
                <div className="formwrap">
                  <div
                    className="content"
                    style={{
                      color: "#333333",
                      fontSize: "20px",
                      fontWeight: 700,
                      lineHeight: "20px",
                      wordWrap: "break-word",
                    }}
                  >
                    Create account
                  </div>
                  <div className="small-content">
                    Please provide the following details to set up your
                    <br /> account. Ensure all information is accurate.
                  </div>
                  <form className="form" onSubmit={handleSubmit}>
                    <div className="DivForInfoButton">
                      {" "}
                      <label htmlFor="username">Organization ID</label>{" "}
                      <div className="inforButtonClass">i</div>
                    </div>

                    <input
                      type="txt"
                      value={organizationID}
                      onChange={(e) => {
                        orgChange(e);
                      }}
                      id="orgid"
                      name="organizationId"
                      placeholder="Enter Organization ID  "
                      className={error ? "error" : ""}
                      required
                    />
                    <div className="DivForInfoButton">
                      <label htmlFor="username">Username</label>
                      <div className="inforButtonClass">i</div>
                    </div>
                    <input
                      type="txt"
                      value={username}
                      onChange={(e) => {
                        userNameChange(e);
                      }}
                      id="username"
                      name="username"
                      placeholder="Enter Username"
                      className={error ? "error" : ""}
                      required
                    />
                    <div className="DivForInfoButton">
                      <label htmlFor="name">Password</label>
                      <div className="inforButtonClass">i</div>
                    </div>

                    <input
                      type="password"
                      value={password}
                      onChange={(e) => {
                        PasswordChange(e);
                      }}
                      id="password"
                      name="password"
                      placeholder="*************"
                      className={error ? "error" : ""}
                      required
                    />
                    <div className="DivForInfoButton">
                      <label htmlFor="name">Password</label>
                      <div className="inforButtonClass">i</div>
                    </div>

                    <input
                      type="password"
                      value={password}
                      onChange={(e) => {
                        PasswordChange(e);
                      }}
                      id="password"
                      name="password"
                      placeholder="*************"
                      className={error ? "error" : ""}
                      required
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          marginBottom: "15px",
                          width: "100%",
                          textAlign: "start",
                        }}
                      >
                        <label>
                          <input
                            type="checkbox"
                            checked={rememberMe}
                            onChange={(e) => setRememberMe(e.target.checked)}
                          />
                          Remember me
                        </label>
                      </div>

                      <div
                        style={{
                          marginBottom: "15px",
                          width: "100%",
                          textAlign: "end",
                        }}
                      >
                        {/*
                      <Link
                        to={"/forgotpassword"}
                        style={{ textDecoration: "underline" }}
                      >
                        Forgot Password ?
                      </Link>
                   */}
                      </div>
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        width: "100%",
                      }}
                    >
                      <button type="submit" className="login-button">
                        {isLoading ? (
                          <div className="spinner"></div> // Spinner will be displayed when loading
                        ) : (
                          "Log In" // Button text when not loading
                        )}
                      </button>
                    </div>
                  </form>
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                    }}
                  >
                    {
                      <p>
                        Don’t have an account?{"  "}
                        <Link
                          to="/creditbazaar/signup"
                          style={{ textDecoration: "underline" }}
                        >
                          Sign Up
                        </Link>
                      </p>
                    }
                  </div>
                </div>
              </div>
            </Sign1>
          </div>
        </div>
      </div>

      {/* <div style={{ marginTop: "20vh" }}> */}
      {/* <Footer /> */}
      {/* </div> */}
    </>
  );
}

export default Signin;

const Sign1 = styled.div`
  .TotalWrap {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 100px;
  }

  .Logo {
    margin: 10px;
    height: fit-content;
  }
  .textComponent {
    display: flex;
    margin: 20px;
    width: 50%;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
  .inforButtonClass {
    border: 1px solid;
    border-radius: 100%;
    height: 15px;
    width: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0.5;
    cursor: pointer;
  }
  .largeText {
    color: white;
    font-size: 40px;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    line-height: 50px;
    word-wrap: break-word;
    width: 60%;
  }
  .DivForInfoButton {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 5px;
  }
  .smallText {
    color: white;
    font-size: 24px;
    font-family: "Inter", sans-serif;
    font-weight: 700;
    line-height: 50px;
    word-wrap: break-word;
    width: 60%;
  }

  .Sign1 {
    display: flex;
    justify-content: center;
    width: 50%;
    background-color: white;
    flex-direction: column;
  }
  /* Styles for the page background */
  body {
    background-color: #e5e4e2;
  }

  /* Styles for the form wrapper */
  .formwrap {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 100px;
    margin-left: 50px;
    margin-right: 50px;
    background-color: white;
    padding: 40px;
    border-radius: 5px;
  }

  /* Styles for the content */
  .content {
    font-size: 18px;
    font-weight: bold;
    color: #333333;
    margin-bottom: 5px;
  }
  .small-content {
    font-size: 16px;
    // font-weight: bold;
    color: #333333;
    margin-bottom: 10px;
  }
  input.error {
    border: 1px solid red;
  }

  .error-message {
    color: red;
    margin-bottom: 15px;
    font-size: 16px;
  }
  /* Styles for the form */
  .form {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  /* Styles for the form labels */
  label {
    font-weight: bold;
    margin-bottom: 5px;
    color: #333333;
  }

  /* Styles for the form inputs */
  input[type="txt"],
  input[type="password"] {
    height: 30px;
    width: 350px;
    margin-bottom: 20px;
    padding: 5px;
  }

  /* Styles for the submit button */
  button[type="submit"] {
    font-size: 16px;
    color: white;
    height: 40px;
    width: 60%;
    margin-bottom: 20px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  .login-button {
    font-size: 16px;
    height: 40px;
    width: 50%;
    margin-bottom: 20px;
    background-color: #007bff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-family: "Open Sans", Arial, sans-serif;
    font-weight: 600;
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  .spinner {
    border: 4px solid rgba(255, 255, 255, 0.3); /* Light grey */
    border-top: 4px solid white; /* White color */
    border-radius: 50%;
    width: 18px;
    height: 18px;
    animation: spin 1s linear infinite;
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }

  /* Styles for the "Don't have an account?" and "Forgot Password?" links */
  div > a {
    margin-top: 20px;
    text-decoration: none;
    color: #333333;
  }

  /* Optional hover effect for links */
  div > a:hover {
    text-decoration: underline;
  }
`;
