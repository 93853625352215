import React, { useCallback, useEffect, useState } from "react";
import { styled } from "styled-components";
import {
  Breadcrumbs,
  CircularProgress,
  Divider,
  Link,
  Typography,
} from "@mui/material";
import TradeReport from "./CreditRecommendationScreens/TradeReport";
import AnchorTraderReport from "./CreditRecommendationScreens/AnchorTraderReport";
import TradePartnerReport from "./CreditRecommendationScreens/TradePartnerReport";
import TransactionReport from "./CreditRecommendationScreens/TransactionReport";
import arrowLeft from "../../components/images/arrow.svg";
import { callAPI } from "../API/FP/Actions";
import { useAuth } from "../../context/auth";
import { contextObj } from "../Common/Constants";
import { useNavigate } from "react-router-dom";
import Pagenotfound from "../Common/pagenotfound";
function CreditRecommendationReport({
  creditScoreReportCheck,
  setCreditScoreReportCheck,
  financeRequestData,
  authToken,
}) {
  const [tradeReport, setTradeReport] = useState(true);
  const [anchorTraderReport, setAnchorTraderReport] = useState(false);
  const [tradePartnerReport, setTradePartnerReport] = useState(false);
  const [transactionReport, setTransactionReport] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [currentInvoicesData, setCurrentInvoicesData] = useState([]);
  const [tradePartnerData, setTradePartnerData] = useState({});
  const [auth, setAuth] = useAuth();

  const authContextObj = {
    transactionDate: new Date().toISOString(),
    userId: auth?.userDetails?.username,
    tenantId: auth?.userDetails?.tenantId,
    personaCode: 3,
    clientCode: 1501,
  };

  const fetchAllInvoices = useCallback(async () => {
    setIsLoading(true);
    try {
      const constructObj = contextObj(
        authContextObj,
        financeRequestData?.requestOffer?.reqOffUlidId
      );
      const tradesResponse = await callAPI(
        auth?.token,
        constructObj,
        "get-fp-trade-details?page=0&size=1000000&sort=id,desc"
      );

      if (tradesResponse?.response?.status === 401) {
        setAuth({
          ...auth,
          user: null,
          token: "",
          userDisplayName: "",
          userDetails: null,
        });
        localStorage.removeItem("auth");
        navigate("/creditbazaar/");
      }

      if (tradesResponse.res.status === 200) {
        setCurrentInvoicesData(tradesResponse.res.data.data);
        console.log("tradesResponse", tradesResponse);

        let tradePartnerDataa = tradesResponse?.res?.data?.data;
        const seenGstIds = new Set();
        let uniqueFilteredData = tradePartnerDataa
          .filter((item) => {
            if (!seenGstIds.has(item.gstId)) {
              seenGstIds.add(item.gstId);
              return true;
            }
            return false;
          })
          .map((item) => item.tradepartner);

        console.log(
          "Processed tradePartnerDataa (unique by gstId):",
          uniqueFilteredData
        );
        setTradePartnerData(uniqueFilteredData);
      }
    } catch (err) {
      console.log("Error", err);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAllInvoices();
  }, []);

  return (
    <>
      {isLoading && (
        <CircularProgress
          style={{ position: "absolute", left: "50%", top: "50%" }}
        />
      )}
      <div>
        <Finance1>
          <div>
            <div
              role="presentation"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                color: "#4A3AFF",
                // marginTop: "50px",
              }}
            >
              <div className="header">
                <div>
                  <img
                    src={arrowLeft}
                    style={{
                      cursor: "pointer",
                      transform: "rotate(180deg)",
                      marginTop: "3px",
                      width: "10px",
                      color: "#4A3AFF",
                    }}
                    onClick={() => setCreditScoreReportCheck(false)}
                  />
                </div>
                <div style={{ marginLeft: "20px" }}>
                  <h3 style={{ margin: "0px" }} className="section-heading">
                    Credit recommendation report
                  </h3>
                </div>
              </div>
            </div>
            <Divider
              style={{ margin: "20px 0px", backgroundColor: "#4A3AFF" }}
            />
            <div style={{ marginTop: "15px" }}>
              <button
                className="report-button"
                onClick={() => {
                  setTradeReport(true);
                  setAnchorTraderReport(false);
                  setTradePartnerReport(false);
                  setTransactionReport(false);
                }}
                style={{
                  backgroundColor: tradeReport ? "#4a3aff" : "white",
                  color: tradeReport ? "white" : "#4a3aff",
                  fontSize: "13px",
                }}
              >
                Trade Report
              </button>
              <button
                className="report-button"
                onClick={() => {
                  setTradeReport(false);
                  setAnchorTraderReport(false);
                  setTradePartnerReport(false);
                  setTransactionReport(true);
                }}
                style={{
                  backgroundColor: transactionReport ? "#4a3aff" : "white",
                  color: transactionReport ? "white" : "#4a3aff",
                  fontSize: "13px",
                }}
              >
                Transaction Report
              </button>
              <button
                className="report-button"
                onClick={() => {
                  setTradeReport(false);
                  setAnchorTraderReport(true);
                  setTradePartnerReport(false);
                  setTransactionReport(false);
                }}
                style={{
                  backgroundColor: anchorTraderReport ? "#4a3aff" : "white",
                  color: anchorTraderReport ? "white" : "#4a3aff",
                  fontSize: "13px",
                }}
              >
                Anchor Trader Report
              </button>
              <button
                className="report-button"
                onClick={() => {
                  setTradeReport(false);
                  setAnchorTraderReport(false);
                  setTradePartnerReport(true);
                  setTransactionReport(false);
                }}
                style={{
                  backgroundColor: tradePartnerReport ? "#4a3aff" : "white",
                  color: tradePartnerReport ? "white" : "#4a3aff",
                  fontSize: "13px",
                }}
              >
                Trade Partner Report
              </button>
            </div>
          </div>
        </Finance1>
        <RequestInformation className="body-text">
          {!isLoading && currentInvoicesData?.length === 0 && tradeReport ? (
            <Pagenotfound />
          ) : (
            tradeReport && (
              <TradeReport
                invoicesData={currentInvoicesData}
                financeRequestData={financeRequestData}
              />
            )
          )}

          {anchorTraderReport && (
            <AnchorTraderReport
              authToken={authToken}
              financeRequestData={financeRequestData}
            />
          )}
          {tradePartnerReport && (
            <TradePartnerReport
              invoicesData={currentInvoicesData}
              financeRequestData={financeRequestData}
              tradePartnerData={tradePartnerData}
            />
          )}
          {transactionReport && (
            <TransactionReport
              authToken={authToken}
              financeRequestData={financeRequestData}
              invoicesData={currentInvoicesData}
            />
          )}
        </RequestInformation>
      </div>
      {/* <div style={{ marginTop: "30vh" }}>
        <Footer />
      </div> */}
    </>
  );
}

export default CreditRecommendationReport;

const Finance1 = styled.div`
  .header {
    font-size: 18px;
    margin-top: 15px;
    margin-left: 10px;
    display: flex;
  }
  .report-button {
    border-radius: 2px;
    background-color: white;
    color: #4a3aff;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    height: fit-content;
    margin-right: 25px;
    border: 1px solid rgba(0, 0, 128, 0.2);
    font-size: 11px;
  }
`;

const RequestInformation = styled.div`
  .content {
    display: flex;
    margin-top: 20px;
    padding: 20px;
  }

  .table-container {
    margin-top: 20px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    // border-left: 1px solid rgba(0, 0, 128, 0.2);
    // border-right: 1px solid rgba(0, 0, 128, 0.2);
    border-top: 1px solid rgba(0, 0, 128, 0.2);
    // color: #4a3aff;
    border-radius: 5px;

    border-style: hidden; /* hide standard table (collapsed) border */
    box-shadow: 0 0 0 1px rgba(0, 0, 128, 0.2);
  }
  table tr:last-child td:first-child {
    // border: 2px solid orange;
    // border-bottom-left-radius: 10px;
  }

  table tr:last-child td:last-child {
    // border: 2px solid green;
    // border-bottom-right-radius: 10px;
    // border-radius: 0px 0px 0px 10px;
  }
  th,
  td {
    padding: 10px;
    text-align: left;
    border-bottom: 1px solid rgba(0, 0, 128, 0.2);
    border-right: 1px solid rgba(0, 0, 128, 0.2);
  }
  th {
    // background-color: rgba(0, 0, 128, 0.2);
    // font-weight: bold;
    color: rgba(97, 94, 131, 1);
    // width: 55%;
    // background: rgba(74, 58, 255, 0.1);
  }
  .main-content {
    margin-top: 30px;
  }
`;
